<template>
  <!-- https://vue-yandex-maps.github.io/examples/#%D0%BA%D0%B0%D1%81%D1%82%D0%BE%D0%BC%D0%BD%D0%B0%D1%8F-%D0%B8%D0%BA%D0%BE%D0%BD%D0%BA%D0%B0 -->
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <yandex-map
        :settings="mapSettings"
        :coords="mapCoords"
        :zoom="16"
        style="max-width: 755px; height: 284px; margin-top: 27px"
      >
        <ymap-marker marker-id="123" :coords="coords" :icon="markerIcon" />
      </yandex-map>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <yandex-map
        :settings="mapSettings"
        :coords="mapCoords"
        :zoom="16"
        style="max-width: 635px; height: 284px; margin-top: 27px"
      >
        <ymap-marker marker-id="123" :coords="coords" :icon="markerIcon" />
      </yandex-map>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapSettings: {
        apiKey: "ca94b5ad-f0d0-4ee5-a6c6-62e078cdd1f0",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
        zoom: 5,
      },
      // controls: ["zoomControl", "fullscreenControl"]
      // :controls="controls",
      mapCoords: [55.468941, 37.714332],
      coords: [55.468009, 37.715183],
      markerIcon: {
        iconLayout: "islands#blackZooIcon",
        iconImageHref: "../assets/img/contact-phone.svg", // Иконка дома
        iconImageSize: [32, 37], // Размер иконки
        iconImageOffset: [-16, -37],
        // content: '123 v12',
        // contentOffset: [0, 15],
        // contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      },
    };
  },
};
</script>
