<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="container-mobile">
        <div class="first-contacts-container-mobile">
          <h2 class="title-name">{{ $t("contactUs") }}</h2>
          <p class="text-company">
            {{ $t("contacts-name") }}
          </p>
          <div class="company-info">
            <p class="address-location">
              {{ $t("contact-address-1") }}
            </p>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-pin.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">
                {{ $t("footer-adress") }}
              </p>
            </div>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-phone.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">{{ $t("footer-tel") }}</p>
            </div>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-world.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">{{ $t("footer-email") }}</p>
            </div>
            <yandex-map></yandex-map>
          </div>
        </div>
        <div class="second-contacts-container-mobile">
          <p class="address-location" style="margin-top: 33px">
            {{ $t("contact-address-2") }}
          </p>
          <div class="contact-content py-1">
            <img
              src="../assets/img/contact-pin.svg"
              class="contact-nav-img"
              alt="contact-nav-img"
            />
            <p class="contact-text">
              {{ $t("warehouse-address") }}
            </p>
          </div>
          <div class="contact-content py-1" style="margin-bottom: 0px">
            <img
              src="../assets/img/contact-phone.svg"
              class="contact-nav-img"
              alt="contact-nav-img"
            />
            <p class="contact-text">{{ $t("footer-tel") }}</p>
          </div>
          <YandexMapWarehouse></YandexMapWarehouse>
        </div>
      </div>
      <div class="btn-container-mobile">
        <button @click="openModalCoopMobile" class="btn-distributor-mobile">
          {{ $t("Application_for_cooperation") }}
        </button>
        <v-dialog
          class="v-dialog-border"
          v-model="showDialogCoopMobile"
          max-width="537"
        >
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("application-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialogCoopMobile">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="name">
                <label>{{ $t("input-company-name") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-FIO") }}</label>
                <span class="red-dot">*</span>
                <input
                  v-model="fullname"
                  ref="fullnameTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("callback-label-3") }}</label>
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name" style="padding-bottom: 14px">
                <label>{{ $t("input-phone") }}</label>
                <span class="red-dot">*</span>
                <input
                  v-model="phoneNum"
                  ref="phoneNumTextarea"
                  v-mask="'+7 (###) ###-##-##'"
                  type="tel"
                  placeholder=""
                  class="name-input py-3"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-message") }}</label>
                <textarea
                  v-model="message"
                  ref="messageTextarea"
                  type="text"
                  placeholder=""
                  class="name-input-message"
                ></textarea>
              </div>
              <div class="text-warning d-flex">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button v-on:click="handleSubmit" class="btn-call-modal" :disabled="!isValidAgree" >
                {{ $t("input-send-aply") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
                  {{ this.messageSnack }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="container">
        <div class="first-contacts-container">
          <h2 class="title-name">{{ $t("contactUs") }}</h2>
          <p class="text-company">
            {{ $t("contacts-name") }}
          </p>
          <div class="company-info">
            <p class="address-location">
              {{ $t("contact-address-1") }}
            </p>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-pin.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">
                {{ $t("footer-adress") }}
              </p>
            </div>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-phone.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">{{ $t("footer-tel") }}</p>
            </div>
            <div class="contact-content py-1">
              <img
                src="../assets/img/contact-world.svg"
                class="contact-nav-img"
                alt="contact-nav-img"
              />
              <p class="contact-text">{{ $t("footer-email") }}</p>
            </div>
            <yandex-map></yandex-map>
          </div>
        </div>
        <div class="second-contacts-container">
          <p class="address-location" style="margin-top: 85px">
            {{ $t("contact-address-2") }}
          </p>
          <div class="contact-content py-1">
            <img
              src="../assets/img/contact-pin.svg"
              class="contact-nav-img"
              alt="contact-nav-img"
            />
            <p class="contact-text">
              {{ $t("warehouse-address") }}
            </p>
          </div>
          <div class="contact-content py-1" style="margin-bottom: 40px">
            <img
              src="../assets/img/contact-phone.svg"
              class="contact-nav-img"
              alt="contact-nav-img"
            />
            <p class="contact-text">{{ $t("footer-tel") }}</p>
          </div>
          <YandexMapWarehouse></YandexMapWarehouse>
        </div>
      </div>
      <div class="btn-container">
        <button @click="showDialog = true" class="btn-distributor">
          {{ $t("Application_for_cooperation") }}
        </button>
        <v-dialog class="v-dialog-border" v-model="showDialog" max-width="537">
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("application-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="name">
                <label>{{ $t("input-company-name") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-FIO") }}</label>
                <span class="red-dot pl-1">*</span>
                <input
                  v-model="fullname"
                  ref="fullnameTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("callback-label-3") }}</label>
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name" style="padding-bottom: 14px">
                <label>{{ $t("input-phone") }}</label>
                <span class="red-dot pl-1">*</span>
                <input
                  v-model="phoneNum"
                  ref="phoneNumTextarea"
                  v-mask="'+7 (###) ###-##-##'"
                  type="tel"
                  placeholder=""
                  class="name-input py-3"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-message") }}</label>
                <textarea
                  v-model="message"
                  ref="messageTextarea"
                  type="text"
                  placeholder=""
                  class="name-input-message"
                ></textarea>
              </div>
              <div class="text-warning d-flex mt-4">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button v-on:click="handleSubmit" class="btn-call-modal" :disabled="!isValidAgree">
                {{ $t("input-send-aply") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
                  {{ this.messageSnack }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import Header2 from "@/components/Header2";
import YandexMap from "@/components/YandexMap";
import YandexMapWarehouse from "../components/YandexMapWarehouse.vue";
import { mask } from "vue-the-mask";
export default {
  name: "Contacts",
  directives: { mask },
  components: {
    Header2,
    YandexMap,
    YandexMapWarehouse, // Импорт компонента маркера
  },
  data() {
    return {
      showDialog: false,
      showDialogCoopMobile: false,
      name: "",
      fullname: "",
      email: "",
      phoneNum: "",
      message: "",
      isPlaceholderHidden: false,
      consentChecked: false,
      snackbar: false,
      messageSnack: ""
    };
  },
  watch: {
    // name() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("nameTextarea");
    //   });
    // },
    // fullname() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("fullnameTextarea");
    //   });
    // },
    // phoneNum() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("phoneNumTextarea");
    //   });
    // },
    // email() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("emailTextarea");
    //   });
    // },
  },
  methods: {
    handleSubmit() {
          //let message = `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`;
          let data = {
            EmailToId: "info@rdprooff.ru",
            EmailToName: "rdprooff.ru",
            EmailSubject: "Заявка на сотрудничество",
            EmailBody: 
            `
            <html>
              <body>
                <p style="color:black"><strong>Компания:</strong> ${this.name || "Не указанно"}</p>
                <p><strong>Имя:</strong> ${this.fullname}</p>
                <p style="color:black"><strong>Электронная почта:</strong> ${this.email || "Не указано"}</p>
                <p><strong>Телефон:</strong> ${this.phoneNum}</p>
                <p style="color:black"><strong> Комментарий:</strong> ${this.message || "Не указано"}</p>
                <p style="color:black">С уважением, <br> Команда сайта <a href="rdprooff.ru">rdprooff.ru</a></p>
              </body>
            </html>
            `
          }

          this.$http
            .post(`api/Email`,data, {
              headers: {
                // Host: 'api.rdprooff.ru',
                'Content-Type': 'application/json',
              }
              // body: {
              //   EmailToId: "dimon.cool2000@gmail.com",
              //   EmailToName: "Alex",
              //   EmailSubject: "TestEmail",
              //   EmailBody: `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`
              // },
            })
            .then(() => {
              // this.resetModal();
              // this.modal = false;
              // this.$emit("changeModal", this.modal);
              // this.$emit("modalOk");
              this.closeDialog();
              this.closeDialogCoopMobile();
              this.snackbar = true;
              this.messageSnack = "Заявка отправленна";
            })
            .catch((error) => {
              this.error = error;
              this.snackbar = true;
              this.messageSnack = "Ошибка в заявки";
            });
    },
    closeDialogCoopMobile() {
      this.showDialogCoopMobile = false;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phoneNum = "";
      this.message = "";
      this.consentChecked = false;
    },
    openModalCoopMobile() {
      this.showDialogCoopMobile = true;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.consentChecked = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phoneNum = "";
      this.message = "";
      this.consentChecked = false;
    },
    openModal() {
      this.showDialog = true;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.consentChecked = false;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "";
    },
    isValidAgree() {
      return this.phoneNum && this.fullname && this.consentChecked;
    },
  },
};
</script>
<style scoped>
 .btn-contaiener {
    display: flex;
    justify-content: space-evenly;
  }
  .snackbar-btn {
    color: var(--03, #bcb648);
    font-family: Inter;
    font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.0178571429em;
      line-height: 1.25rem;
      margin-right: auto;
      padding: 14px 16px;
      text-align: initial;
    cursor: pointer;
  
  }
.btn-call-modal:disabled {
  background-color: grey; /* Серый цвет фона для неактивной кнопки */
  color: white; /* Цвет текста на неактивной кнопке (белый, чтобы контрастировать с серым фоном) */
}
.btn-call-modal {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px auto;
}
.text-warning {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}
.policy-text-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkbox-container {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.custom-checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.name {
  padding-bottom: 14px;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: white; /* Белый фон, когда не выбран */
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.custom-checkbox:checked + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе */
}

/* .custom-checkbox:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

.custom-checkbox:checked:hover + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе и наведении */
}
.checkbox-container svg {
  width: 80%;
  height: 80%;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--light-white, #fff);
  align-items: center;
  position: absolute;
  top: 2px;
  left: 2px;
}
.name-input:focus {
  border-color: #dae3ea; /* Замените цвет на ваш желаемый цвет */
  outline: none;
}
.name {
  padding-bottom: 14px;
}
.name-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  margin-top: 10px;
  resize: none;
  overflow-y: hidden;
  height: 45px;
  font-size: 16px;
  padding: 10px;
}
.name-input-message {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  margin-top: 10px;
  resize: none;
  overflow-y: hidden;
  padding-top: 15px;
  height: 125px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.name > label {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
::v-deep .v-dialog {
  border-radius: 20px !important;
  background: var(--06, #fff) !important;
}
.application-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.btn-distributor-mobile {
  width: 100%;
  max-width: 319px;
  margin: 0 16px;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-container-mobile {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.contact-text-mobile {
  margin-top: 0px;
}
.second-contacts-container-mobile {
  max-width: 755px;
  width: 100%;
}
.first-contacts-container-mobile {
  max-width: 755px;
  width: 100%;
}
.container-mobile {
  display: flex;
  justify-content: center;
  /* margin-top: 4.62962962963vh; */
  margin-top: 10px;
  margin-bottom: 32px;
  padding-right: 16px;
  padding-left: 16px;
  align-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.btn-container {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: center;
  /* margin-top: 4.62962962963vh; */
  margin-top: 10px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  align-content: flex-start;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  max-width: 1337px;
}
.title-name {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.contact-span-special {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 33px;
}
.contact-content-special {
  display: flex;
  flex-direction: column;
}
.contact-text-special {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* line-height: 215.9%; */
  align-items: center;
  display: flex;
}
.contact-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 215.9%; /* 34.544px */
}
.contact-nav-img {
  width: 24px;
  height: 24px;
  margin-right: 9px;
  color: #000;
}
.company-info {
  margin-top: 18px;
}
.first-contacts-container {
  max-width: 640px;
  width: 100%;
  margin-right: 32px;
}
.second-contacts-container {
  display: flex;
  max-width: 640px;
  width: 100%;
  flex-direction: column;
}
.contact-page-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.address-location {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn-distributor {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 700px) {
  /* Если ширина экрана больше 768px, кнопки будут располагаться горизонтально */
  ::v-deep .v-card__title {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: inherit;
    align-items: baseline;
  }

  .v-dialog > .v-card > .v-card__subtitle,
  .v-dialog > .v-card > .v-card__text {
    padding: 0 18px 10px;
  }

  .btn-call-modal {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    border-radius: 4px;
    background: var(--03, #bcb648);
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin: 30px auto; */
    /* text-align: center; */
    width: 100%;
    margin-top: 31px;
    margin-bottom: 15px;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    padding: 16px 24px 10px;
    padding-top: 13px;
    padding-right: 17px;
    padding-bottom: 10px;
    padding-left: 14px;
  }
  .checkbox-container {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid black;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}
</style>
